<template>
  <v-container fluid>
    <v-alert
        dense
        :type="alert.type"
        timeout="5000"
    v-if="alert.message">
        {{ alert.message }}
    </v-alert>
    <v-layout row wrap>
    <template>
      <v-flex
        sm=12
        xs=12
        md=12
        lg=12
      >
        <v-card>
          <v-card-title>
            <v-row>
							<v-col cols="auto">
                <!--
								<v-btn
									color="blue"
									class="mx-2"
									@click="doToggleMode()"
								>
									Mode: {{Mode}}
								</v-btn>
              -->
              <v-menu offset-y>
                <template v-slot:activator="{ on, attrs }">
                  <v-btn
                    color="blue"
                    dark
                    v-bind="attrs"
                    v-on="on"
                    small
                  >
                    Mode: {{ Mode }}
                  </v-btn>
                </template>
                <v-list>
                  <v-list-item v-if="this.newData.type !== 'apps'">
                    <v-list-item-title>
                      <v-btn value="justify" color="green darken-01" small @click="doToggleMode('apps')">
                        Apps
                      </v-btn>
                    </v-list-item-title>
                  </v-list-item>
                  <v-list-item  v-if="this.newData.type !== 'solibs'">
                    <v-list-item-title>
                      <v-btn value="justify" color="green" small @click="doToggleMode('solibs')">
                        .so Libs
                      </v-btn>
                    </v-list-item-title>
                  </v-list-item>
                  <v-list-item v-if="this.newData.type !== 'drivers'">
                    <v-list-item-title>
                      <v-btn value="justify" color="green darken-02" small @click="doToggleMode('drivers')">
                        Drivers
                      </v-btn>
                    </v-list-item-title>
                  </v-list-item>
                  <v-list-item v-if="this.newData.type !== 'apk'">
                    <v-list-item-title>
                      <v-btn value="justify" color="green darken-03" small @click="doToggleMode('apk')">
                        Apk
                      </v-btn>
                    </v-list-item-title>
                  </v-list-item>
                </v-list>
              </v-menu>
							</v-col>
              <v-col cols="auto">
                <v-btn color="green" @click="doOpenNewBox" class="mx-2">
                  New Version
                </v-btn>
              </v-col>
              <v-col cols="auto">
                <v-autocomplete 
                  v-model="search_by_seller" 
                  label="Seller"
                  :items="sellers"
                  clearable
                >
                  <v-icon>
                      mdi-update
                  </v-icon>
                </v-autocomplete>
              </v-col>
              <v-spacer />
              <v-col cols="12" sm=6 md=6 lg=8 xl=8>
                <v-text-field
                  v-model="search"
                  append-icon="mdi-magnify"
                  label="Search"
                  single-line
                  hide-details
                ></v-text-field>
              </v-col>
            </v-row>
          </v-card-title>
          <v-data-table
            :headers="Headers"
            :items="Items"
            :search="search"
            item-key="updateId"
            :loading="Overlay"
            loading-text="Loading... Please wait"
            :footer-props="{'items-per-page-options':[15,30,50,100]}"
            :items-per-page="15"
          >
            <template v-slot:[`item.disabled`]="{ item }">
              <v-switch
                :value="!item.disabled"
                :input-value="!item.disabled"
                :label="item.disabled ? 'Disabled':'Enabled'"
                @change="doDisable(item,!item.disabled)"
              />
            </template>
            <template v-slot:[`item.allow`]="{ item }">
              <v-switch
                :value="item.allow"
                :input-value="item.allow"
                :label="item.allow ? 'Allow':'Disable'"
                @change="doStatus(item,!item.allow)"
              />
            </template>
            <template v-slot:[`item.fileDownload`]="{ item }">
              <v-textarea
                      class="mx-2"
                      label="URL"
                      rows="2"
                      :value="item.fileDownload"
                      readonly
              />
            </template>
            <template v-slot:[`item.changelogs`]="{ item }">
              <temlate 
                v-if="onEditLog !== item.updateId"
              >
                <v-textarea
                  class="mx-2"
                  label="Changelogs"
                  rows="2"
                  prepend-icon="mdi-comment"
                  :value="item.changelogs"
                  readonly
                />
                <v-btn icon dark @click="onEditLog = item.updateId">
                  <v-icon>
                    mdi-pencil
                  </v-icon>
                </v-btn>
              </temlate>
              <EditLogs v-if="onEditLog === item.updateId" :content="item.changelogs" @onUpdate="doUpdateLog"/>
            </template>
            <template v-slot:[`item.filesize`]="{ item }">
              {{formatSize(item.filesize)}}
            </template>
            <template v-slot:[`item.x86`]="{ item }">
              <v-checkbox
                :input-value="item.x86"
                :label="item.x86 ? 'x86':'x64'"
                @change="doUpdateX86(item)"
                v-if="!loaders.includes(`x86-${item.updateId}`)"
              />
              <v-progress-circular
                  indeterminate
                  size="32"
                  v-else
              />
            </template>
            <template v-slot:[`item.md5hash`]="{ item }">
              <v-tooltip bottom v-if="item.md5hash">
                <template v-slot:activator="{ on, attrs }">
                  <v-icon
                      color="green"
                      dark
                      v-bind="attrs"
                      v-on="on"
                  >
                    mdi-check
                  </v-icon>
                </template>
                <span class="blue--text">{{ item.md5hash }}</span>
              </v-tooltip>
              <v-icon
                  color="red"
                  dark
                  v-else
              >
                mdi-cancel
              </v-icon>
            </template>
            <template v-slot:[`item.apk_version`]="{ item }">
              <span v-text="ApkVersionArray[item.apk_version -1 ]" />
            </template>
            <template v-slot:[`item.apk_lib_so_md5`]="{ item }">
              <v-tooltip bottom v-if="item.apk_lib_so_md5">
                <template v-slot:activator="{ on, attrs }">
                  <v-icon
                      color="green"
                      dark
                      v-bind="attrs"
                      v-on="on"
                      @click="doOpenEditLibHashDialog(item)"
                  >
                    mdi-check
                  </v-icon>
                </template>
                <span class="blue--text">{{ item.apk_lib_so_md5 }}</span>
              </v-tooltip>
              <v-icon
                  color="red"
                  dark
                  v-else
                  @click="doOpenEditLibHashDialog(item)"
              >
                mdi-cancel
              </v-icon>
            </template>
            <template v-slot:[`item.beta`]="{ item }">
              <v-checkbox
                :input-value="item.beta"
                label="Beta"
                @change="doUpdateBeta(item)"
              />
            </template>
            <template v-slot:[`item.outdated`]="{ item }">
              <v-checkbox
                :input-value="item.outdated"
                label="Outdated"
                @change="doUpdateOutdated(item)"
              />
            </template>
            <template v-slot:[`item.updateId`]="{ item }">
              <v-menu offset-y>
                <template v-slot:activator="{ on, attrs }">
                  <v-btn
                    color="deep-orange darken-1"
                    dark
                    v-bind="attrs"
                    v-on="on"
                    small
                  >
                    Manager
                  <v-icon
                    light
                    right
                  >
                    mdi-pencil
                  </v-icon>
                  </v-btn>
                </template>
                <v-list>
                  <v-list-item>
                    <v-list-item-title>
                      <v-btn value="justify" color="warning" text small @click="doDelete(item.updateId)">
                        <v-icon
                          light
                          left
                        >
                          mdi-delete
                        </v-icon>
                        Delete
                      </v-btn>
                    </v-list-item-title>
                  </v-list-item>
                  <v-list-item>
                    <v-list-item-title>
                      <v-btn value="justify" color="green" text small @click="doDownload(item)">
                        <v-icon
                          light
                          left
                        >
                          mdi-cloud-download
                        </v-icon>
                        Download
                      </v-btn>
                    </v-list-item-title>
                  </v-list-item>
                </v-list>
              </v-menu>
            </template>
        </v-data-table>
        </v-card>
      </v-flex>
    </template>
    </v-layout>
    <v-dialog
      v-model="boxAdd"
      max-width="600px"
      persistent
    >
      <v-card>
        <validation-observer
          ref="observer"
          v-slot="{ invalid }"
        >
        <v-card-title>New Version</v-card-title>
        <v-card-text>
          <v-row>
            <v-col
              cols=12
							v-if="newData.type === 'apps' || newData.type === 'solibs' || newData.type === 'apk'"
            >
              <validation-provider
                v-slot="{ errors }"
                name="Version"
                rules="required|min:1"
              >
                <v-text-field v-model="newData.version" label="Version" :error-messages="errors">
                  <v-icon
                      slot="prepend"
                  >
                      mdi-update
                  </v-icon>
                </v-text-field>
              </validation-provider>
            </v-col>
            <v-col
              cols=12
              v-if="newData.type === 'drivers'"
            >
              <validation-provider
                v-slot="{ errors }"
                name="Hash"
                rules="required|min:1"
              >
                <v-text-field v-model="newData.sechash" label="Hash" :error-messages="errors" />
              </validation-provider>
            </v-col>
            <v-col
              cols=12
							v-if="newData.type === 'apps'"
            >
              <validation-provider
                v-slot="{ errors }"
                name="Seller"
                rules="required"
              >
                <v-autocomplete 
                  v-model="newData.seller" 
                  label="Seller"
                  :items="sellers"
                  :error-messages="errors"
                >
                  <v-icon>
                      mdi-update
                  </v-icon>
                </v-autocomplete>
              </validation-provider>
            </v-col>
            <v-col
              cols=12
							v-if="newData.type === 'apps'"
            >
              <v-switch
                v-model="newData.allow"
                label="Allow run without update ?"
              />
            </v-col>
            <v-col
              cols=12
            >
              <!-- <v-file-input
                  v-model="fileUpload"
                  counter
                  show-size
                  truncate-length="15"
              /> -->
<!--              <v-autocomplete-->
<!--                v-model="newData.fileDownload"-->
<!--                :items="files"-->
<!--                label="File URL"-->
<!--              />-->

            </v-col>
            <v-col
                cols="6"
								v-if="newData.type === 'apps'"
            >
              <v-checkbox
                  v-model="newData.is_md5"
                  label="Is MD5 ?"
              />
            </v-col>
            <v-col
                cols="6"
                v-if="newData.type === 'apps'"
            >
              <v-checkbox
                  v-model="newData.x86"
                  label="x86"
              />
            </v-col>
            <v-col
              cols="12"
							v-if="newData.type === 'apps' || newData.type === 'apk'"
              >
              <v-textarea
                v-model="newData.changelogs"
                label="Changelogs"
                />
            </v-col>
            <v-col
                cols="12"
                v-if="newData.type !== 'solibs' && newData.type !== 'apk'"
            >
              <v-checkbox
                  v-model="newData.beta"
                  label="Beta ?"
              />
            </v-col>
            <v-col
                cols="12"
                v-if="newData.type === 'apk'"
            >
              <v-autocomplete
                  v-model="newData.apk_version"
                  :items="ApkVersion"
                  label="Apk Version"
              />
            </v-col>
            <v-col
                cols="12"
                v-if="newData.type === 'apk'"
            >
              <v-checkbox
                v-model="apkSoLibMd5"
                label=".so Lib Check MD5 ?"
              />
              <template
              v-if="apkSoLibMd5"
              >
                <validation-provider
                  v-slot="{ errors }"
                  name="MD5"
                  rules="required|min:16"
                >
                <v-text-field
                    v-model="newData.apk_lib_so_md5"
                    label=".so Lib MD5"
                    required
                    :error-messages="errors"
                />
                </validation-provider>
              </template>
            </v-col>
            <v-col
                cols="12"
                v-if="newData.type === 'apk'"
            >
              <FM @onPick="doPickFile" v-if="!newData.fileDownload" />
              <template v-else>
                <v-text-field
                  v-model="newData.fileDownload"
                  label="Apk"
                  readonly
                >
                <template v-slot:append>
                  <v-btn
                    icon
                    @click="doRepick"
                  >
                    <v-icon>mdi-refresh</v-icon>
                  </v-btn>
                </template>
                </v-text-field>
              </template>
            </v-col>
            <v-tabs
              v-model="UploadMethod"
              fixed-tabs
              background-color="blue darken-2"
              dark
              v-if="newData.type !== 'apk'"
            >
              <v-tab key="new">
                New Upload Method
              </v-tab>
              <v-tab key="old">
                Old Upload Method
              </v-tab>
            </v-tabs>
            <v-tabs-items v-model="UploadMethod"  v-if="newData.type !== 'apk'">
              <v-tab-item key="new" full>
                <v-card>
                  <v-card-text>
                    <!--
                    <vue-dropzone ref="myDropzone" id="dropzone" :options="DropZoneOptions" v-on:vdropzone-sending="doDropZoneJS" v-on:vdropzone-processing="doDropZoneProcessing" v-on:vdropzone-complete="doDropZoneJSCompleted" v-on:vdropzone-error="doDropZoneJSError" v-on:vdropzone-total-upload-progress="doDropZoneJSSyncProcess" style="width: 550px; max-width: 550px" />
                    -->
                    <input type="file" @change="selectFile" />
                  </v-card-text>
                </v-card>
              </v-tab-item>
              <v-tab-item key="old"  full-width>
                <v-file-input
                    v-model="fileUpload"
                    counter
                    show-size
                    truncate-length="15"
                    style="width: 550px;"
                />
              </v-tab-item>
          </v-tabs-items>
          </v-row>
        </v-card-text>
        <v-card-actions>
          <v-btn
            @click="boxAdd = false"
          >
            Close
          </v-btn>
          <template v-if="newData.type === 'apk'">
            <v-spacer />
            <v-btn
              :disabled="invalid"
              color="green"
              @click="doAddAPK"
            >
              Add
            </v-btn>
          </template>
          <template v-if="UploadMethod === 1">
            <v-spacer />
            <v-btn
              :disabled="invalid"
              color="green"
              @click="doNewUpload"
            >
              Add
            </v-btn>
          </template>
        </v-card-actions>
        </validation-observer>
      </v-card>
      <v-overlay :value="Overlay">
        <template 
                  v-if="isUploader"
        >
          <v-progress-circular
              :rotate="180"
              :size="64"
              :value="CorrectPercent"
              color="blue"
          >
              {{ CorrectPercent }}%
          </v-progress-circular>
          <v-btn text color="blue">
            {{ fileUploadRate }}
          </v-btn>
        </template>
        <v-progress-circular
          indeterminate
          size="64"
          v-else
        >
        </v-progress-circular>
      </v-overlay>
    </v-dialog>
    <EditSolibHash @onUpdate="doUpdateLibHash" :onLoad="Overlay" ref="editLibDialog" />
    <v-overlay :value="Overlay">
      <template 
                v-if="isUploader"
      >
        <v-progress-circular
            :rotate="180"
            :size="64"
            :value="CorrectPercent"
            color="blue"
        >
            {{ CorrectPercent }}%
        </v-progress-circular>
        <v-btn text color="blue">
          {{ fileUploadRate }}
        </v-btn>
      </template>
      <v-progress-circular
        indeterminate
        size="64"
        v-else
      >
      </v-progress-circular>
    </v-overlay>
  </v-container>
</template>

<script>
import { fetchData } from '../helpers';
import notice from '../helpers/notice.js';
import http from '../helpers/http';
import { mapState, mapActions } from 'vuex'
import { clipboard } from 'vue-clipboards';
import { required, digits, min, max, regex } from 'vee-validate/dist/rules'
import { extend, ValidationObserver, ValidationProvider, setInteractionMode } from 'vee-validate'
import vue2Dropzone from 'vue2-dropzone'
import 'vue2-dropzone/dist/vue2Dropzone.min.css'
import FM from '../views/FileServe.vue'
import EditLogs from '../components/EditLogs.vue';
import EditSolibHash from './EditSolibHash.vue';
setInteractionMode('eager')
  extend('digits', {
    ...digits,
    message: '{_field_} needs to be {length} digits. ({_value_})',
  })
  extend('required', {
    ...required,
    message: '{_field_} can not be empty',
  })
  extend('min', {
    ...min,
    message: '{_field_}  must be {length} or more',
  })
  extend('max', {
    ...max,
    message: '{_field_} may not be greater than {length} characters',
  })
  extend('regex', {
    ...regex,
    message: '{_field_} {_value_} does not match {regex}',
  })

export default {
  name: 'Updates',
  directives: { clipboard },
  components: {
    EditSolibHash,
    EditLogs,
    FM,
    ValidationObserver,
    ValidationProvider,
    vueDropzone: vue2Dropzone,

  },
  data () {
    return {
      onEditLibHash: null,
      onEditLog:null,
      search_by_seller: null,
      UploadStatics: {
        speed: 0,
        percent: '',
      },
      UploadMethod: "new",
      Overlay: false,
      boxAdd: false,
      fileUploadPercent: 0,
      fileUploadRate: 0,
      isUploader: false,
      selectedFile: null,
      fileUploadNew: null,
      fileUpload: null,
      apkSoLibMd5: true,
      newData: {
				type:'apps',
        x86: 1,
        sechash: '',
        apk_version: 1,
        apk_lib_so_md5: '',
        is_apk: 0,
			},
      search: '',
      items: [],
      sellers: [],
      files: [],
      loaders:[],
      headers: [
        { text: 'Version', align: 'center', sortable: false,value: 'version',width:"auto" },
        { text: 'Allow Run without Update', align: 'center', sortable: true,value: 'allow',width:"auto"},
        { text: 'Beta', align: 'center', sortable: true,value: 'beta',width:"auto"},
        { text: 'Seller', align: 'center', sortable: true,value: 'seller',width:"auto"},
        { text: 'File URL', align: 'center', sortable: true,value: 'fileDownload',width:"auto"},
        { text: 'Change logs', align: 'center', sortable: false,value: 'changelogs',width:"auto"},
        { text: 'x86', align: 'center', sortable: false,value: 'x86',width:"auto"},
        { text: 'MD5 Hash ?', align: 'center', sortable: false,value: 'md5hash',width:"auto"},
        { text: 'Size', align: 'center', sortable: false,value: 'filesize',width:"auto"},
        { text: 'Disable', align: 'center', sortable: false,value: 'disabled',width:"auto"},
        { text: 'Manage', align: 'center', sortable: false,value: 'updateId',width: "auto" },
      ],
      solibs: [
        { text: 'Version', align: 'center', sortable: false,value: 'version',width:"auto" },
        { text: 'File URL', align: 'center', sortable: true,value: 'fileDownload',width:"auto"},
        { text: 'Manage', align: 'center', sortable: false,value: 'updateId',width: "auto" },
      ],
			drivers: [
				{ text: 'File URL', align: 'center', sortable: true,value: 'fileDownload',width:"auto"},
        { text: 'Hash', align: 'center', sortable: true,value: 'sechash',width:"auto"},
        { text: 'Beta', align: 'center', sortable: true,value: 'beta',width:"auto"},
        { text: 'Size', align: 'center', sortable: false,value: 'filesize',width:"auto"},
				{ text: 'Manage', align: 'center', sortable: false,value: 'updateId',width: "auto" },
			],
      apks: [
        { text: 'Version', align: 'center', sortable: false,value: 'version',width:"auto" },
        { text: 'File URL', align: 'center', sortable: true,value: 'fileDownload',width:"auto"},
        { text: 'Change logs', align: 'center', sortable: false,value: 'changelogs',width:"auto"},
        { text: 'MD5', align: 'center', sortable: false,value: 'md5hash',width:"auto"},
        { text: 'MD5 Lib', align: 'center', sortable: false,value: 'apk_lib_so_md5',width:"auto"},
        { text: 'Version', align: 'center', sortable: false,value: 'apk_version',width:"auto"},
        { text: 'Disable', align: 'center', sortable: false,value: 'disabled',width:"auto"},
        { text: 'Outdated', align: 'center', sortable: false,value: 'outdated',width:"auto"},
        { text: 'Manage', align: 'center', sortable: false,value: 'updateId',width: "auto" },
      ],
    }
  },
  computed: {
    ...mapState('auth', ['status']),
    ...mapState({
        alert: state => state.alert
    }),
    ApkVersionArray(){
      return ["Global", "Vietnam", "Korea", "Taiwan"]
    },
    ApkVersion(){
      return [{value: 1, text: 'Global'}, {value: 2, text: 'Vietnam'}, {value: 3, text:'Korea'}, {value: 4, text: 'Taiwan'}]
    },
		Mode(){
			//return this.newData.type === 'apps' ? 'APP' : 'Driver';
      switch(this.newData.type){
        case "apps":
          return "APP"
        case "drivers":
          return "Driver"
        case "solibs":
          return ".So Lib"
        case "apk":
          return "APK File"
      }
		},
		Items(){
			return this.items.filter(item => item.type === this.newData.type && (this.search_by_seller ? item.seller === this.search_by_seller : true));
		},
		Headers(){
			//return this.newData.type === 'apps' ? this.headers : this.drivers;
      switch(this.newData.type){
        case "apps":
          return this.headers;
        case "drivers":
          return this.drivers;
        case "solibs":
          return this.solibs;
        case "apk":
          return this.apks;
      }
		},
    DropZoneOptions() {
      return {
        url: `${this.$API}/api/Update/add`,
        headers:{
          'Authorization': localStorage.getItem('Token'),
        }
      }
    },
    CorrectPercent(){
      return Math.floor(this.fileUploadPercent);
    }
  },
  methods: {
    ...mapActions('auth', ['login', 'logout']),
    ...mapActions('alert', ['success', 'error']),
    doOpenEditLibHashDialog(item){
      this.onEditLibHash = item.updateId;
      this.$refs.editLibDialog.open(item.apk_lib_so_md5);
    },
    doUpdateLibHash(hash){
      if(!this.onEditLibHash){
        notice.error("Unknown Error");
        return;
      }
      this.Overlay = true;
      fetchData(`${this.$API}/api/Update/lib-hash`, {
        id: this.onEditLibHash,
        hash,
      }).then((json)=>{
        let message = (json.msg) ? json.msg:"Server Error !!!";
          if(json.success == true){
              this.success(message);
              this.fetchData();
              notice.success({message});
          }else{
              this.error(message);
              notice.error({message});
          }  
      },(err)=>{
        if(err === 401){
          this.logout();
        }else{
          this.error('SERVER ERROR !!!');
        }
      }).finally(()=>{
        this.Overlay = false;
        this.$refs.editLibDialog.cancel();
      }); 
    },
    doUpdateLog(logs){
      if(logs === false){
        this.onEditLog = null;
        return
      }
      this.Overlay = true;
      fetchData(`${this.$API}/api/Update/logs`, {
        id: this.onEditLog,
        logs,
      }).then((json)=>{
        let message = (json.msg) ? json.msg:"Server Error !!!";
          if(json.success == true){
              this.success(message);
              this.fetchData();
              notice.success({message});
          }else{
              this.error(message);
              notice.error({message});
          }  
      },(err)=>{
        if(err === 401){
          this.logout();
        }else{
          this.error('SERVER ERROR !!!');
        }
      }).finally(()=>{
        this.Overlay = false;
        this.onEditLog = null;
      }); 
    },
    doRepick(){
      this.newData.fileDownload = null;
    },
    doPickFile(f){
      if(f && f.file_name){
        this.$set(this.newData, 'fileDownload', f.file_name);
        this.$set(this.newData, 'is_apk', 1);
        this.$set(this.newData, 'md5hash', f.hash);
      }
    },
    doDownload(item){
      const dlink = item.fileDownload.replace('https://dl.egmokka.com', 'https://update.egmokka.com/private-dlink');
      window.open(dlink, '_blank');
    },
    selectFile(event){
       this.fileUpload = event.target.files[0];
       return this.doNewUpload();
    },
    doAddAPK(){
      this.Overlay = true;
      fetchData(`${this.$API}/api/Update/apk`,this.newData).then((json)=>{
            let message = (json.msg) ? json.msg:"Server Error !!!";
              if(json.success == true){
                  this.newData.version = null;
                  this.newData.apk_lib_so_md5 = "";
                  this.newData.apk_version = 1;
                  this.newData.fileDownload = null;
                  this.newData.is_apk = 0;
                  this.success(message);
                  this.fetchData();
              }else{
                  this.error(message);
              }  
          },(err)=>{
            if(err === 401){
              this.logout();
            }else{
              this.error('SERVER ERROR !!!');
            }
          }).finally(()=>{
            this.Overlay = false;
            this.boxAdd = false;
          }); 
    },
    doNewUpload(){
      if(!this.fileUpload){
        this.error('Invalid File');
        return;
      }
      const formData = new FormData();
      formData.append('file',  this.fileUpload);
      // formData.append('data', JSON.stringify(this.newData));
      const app = this;
      this.doDropZoneProcessing();
      return http.post(`${this.$API}/api/upload/do`, formData, {
        headers: {
          'Authorization': 'Bearer 1+1',
          'Seller': this.newData.type === 'apps' ? this.newData.seller.toLowerCase():this.newData.type === 'drivers' ? 'mokka.drivers':'solibs/'+this.newData.version,
          "Content-Type": "multipart/form-data"
        },
        onUploadProgress: (progressEvent)=>{
          const percentCompleted = Math.round((progressEvent.loaded / progressEvent.total) * 100);
          const uploadRateKBs = (progressEvent.rate ? progressEvent.rate / 1024 : -1);
          app.fileUploadPercent = percentCompleted;
          if (uploadRateKBs > 1024) {
            app.fileUploadRate = (uploadRateKBs / 1024).toFixed(2) + ' MB/s';
          } else {
            app.fileUploadRate = uploadRateKBs > 0 ? uploadRateKBs.toFixed(2) + ' KB/s' : 'Calculating';
          }
          //app.fileUploadRate = uploadRateKBs > 0  ? uploadRateKBs.toFixed(2) + ' KB/s':'Calculating';
        }
      }).then((res)=>{
          const status = res.data;
          this.newData.uploaded = status;
          fetchData(`${this.$API}/api/Update/new_add`,this.newData).then((json)=>{
            let message = (json.msg) ? json.msg:"Server Error !!!";
              if(json.success == true){
                  this.selectedFile = null;
                  this.fileUpload = null;
                  this.fileUploadPercent = 0;
                  this.success(message);
                  this.fetchData();
              }else{
                  this.error(message);
              }  
          },(err)=>{
            if(err === 401){
              this.logout();
            }else{
              this.error('SERVER ERROR !!!');
            }
          }).finally(()=>{
            this.Overlay = false;
            this.boxAdd = false;
          }); 
          //`${this.$API}/api/Update/add`
          // let message = (json.msg) ? json.msg:"Server Error !!!";
          //   if(json.success == true){
          //       this.selectedFile = null;
          //       this.fileUpload = null;
          //       this.fileUploadPercent = 0;
          //       this.success(message);
          //       this.fetchData();
          //   }else{
          //       this.error(message);
          //   }  
      },(err)=>{
        if(err === 401){
          this.logout();
        }else{
          this.error(`File Upload Error: ${err}`);
        }
      }).finally(()=>{
        this.Overlay = false;
        this.boxAdd = false;
        this.selectedFile = null;
        this.fileUpload = null;
        this.fileUploadPercent = 0;
      });
    },
    formatSize(bytes) {
      if (bytes >= 1024 * 1024) {
        // If size is greater than or equal to 1 MB, format as MB
        return (bytes / (1024 * 1024)).toFixed(2) + " MB";
      } else {
        // Otherwise, format as KB
        return (bytes / 1024).toFixed(2) + " KB";
      }
    },
    doUpdateX86(item){
      const loaderID = `x86-${item.updateId}`
      this.loaders.push(loaderID);
      fetchData(`${this.$API}/api/Update/x86`,{
        updateId:item.updateId,
        x86: item.x86 ? 0 : 1,
      }).then((json)=>{
        let message = (json.msg) ? json.msg:"Server Error !!!";
        if(json.success == true){
          this.success(message);
          const updateIndex = this.items.findIndex(i => i.updateId === item.updateId);
          this.items[updateIndex].x86 = item.x86 ? 0 : 1;
        }else{
          this.error(message);
        }
      },(err)=>{
        if(err === 401){
          this.logout();
        }else{
          this.error('SERVER ERROR !!!');
        }
      }).finally(()=>{
        const loaderIndex = this.loaders.findIndex(i => i === loaderID);
        this.loaders.splice(loaderIndex,1);
      });
    },
    doUpdateOutdated(item){
      this.Overlay = true;
      fetchData(`${this.$API}/api/Update/outdated`,{
        updateId:item.updateId,
        outdated: !item.outdated,
      }).then((json)=>{
        let message = (json.msg) ? json.msg:"Server Error !!!";
        if(json.success == true){
          this.success(message);
          this.fetchData();
        }else{
          this.error(message);
        }
      },(err)=>{
        if(err === 401){
          this.logout();
        }else{
          this.error('SERVER ERROR !!!');
        }
      }).finally(()=>{
        this.Overlay = false;
      });
    },
    doUpdateBeta(item){
      this.Overlay = true;
      fetchData(`${this.$API}/api/Update/beta`,{
        updateId:item.updateId,
        beta: !item.beta,
      }).then((json)=>{
        let message = (json.msg) ? json.msg:"Server Error !!!";
        if(json.success == true){
          this.success(message);
          this.fetchData();
        }else{
          this.error(message);
        }
      },(err)=>{
        if(err === 401){
          this.logout();
        }else{
          this.error('SERVER ERROR !!!');
        }
      }).finally(()=>{
        this.Overlay = false;
      });
    },
		doOpenNewBox(){
			if(this.newData.type === 'drivers'){
				this.fetchFiles();
			}
			this.boxAdd = true;
		},
    doToggleMode(Mode){
			this.newData.type = Mode;
      if(Mode === 'apk'){
        this.newData.is_apk = 1;
      }else{
        this.newData.is_apk = 0;
      }
		},
		doToggleModeOld(){
			this.newData.type = this.newData.type === 'apps' ? 'drivers' : 'apps';
		},
    fetchData: function(request){
      this.Overlay = true;
      fetchData(`${this.$API}/api/Update/get`,request).then((json)=>{
          this.items = json;
      },(err)=>{
        if(err === 401){
          this.logout();
        }else{
          this.error('SERVER ERROR !!!');
        }
      }).finally(()=>{
        this.Overlay = false;
      });
    },
    fetchSellers(){
      this.Overlay = true;
      fetchData(`${this.$API}/api/Seller/getName`).then((json)=>{
        this.sellers = this.sellers.concat(json);
      },(err)=>{
        if(err === 401){
          this.logout();
        }else{
          this.error('SERVER ERROR !!!');
        }
      }).finally(()=>{
        this.Overlay = false;
      });
    },
    fetchFiles(){
      this.Overlay = true;
      fetchData(`${this.$API}/api/Update/files`,{seller:this.newData.seller,mode:this.newData.type}).then((json)=>{
        this.files = json.data;
      },(err)=>{
        if(err === 401){
          this.logout();
        }else{
          this.error('SERVER ERROR !!!');
        }
      }).finally(()=>{
        this.Overlay = false;
      });
    },
    doStatus(item,allow){
      this.Overlay = true;
      fetchData(`${this.$API}/api/Update/status`,{
        updateId:item.updateId,
        allow
      }).then((json)=>{
          let message = (json.msg) ? json.msg:"Server Error !!!";
            if(json.success == true){
                this.success(message);
                this.fetchData();
            }else{
                this.error(message);
            }  
      },(err)=>{
        if(err === 401){
          this.logout();
        }else{
          this.error('SERVER ERROR !!!');
        }
      }).finally(()=>{
        this.Overlay = false;
        this.boxAdd = false;
      });
    },
    doDisable(item,disabled){
      this.Overlay = true;
      fetchData(`${this.$API}/api/Update/disable`,{
        updateId:item.updateId,
        disabled
      }).then((json)=>{
          let message = (json.msg) ? json.msg:"Server Error !!!";
            if(json.success == true){
                this.success(message);
                notice.success({message});
                this.fetchData();
            }else{
                this.error(message);
                notice.error({message});
            }  
      },(err)=>{
        if(err === 401){
          this.logout();
        }else{
          this.error('SERVER ERROR !!!');
        }
      }).finally(()=>{
        this.Overlay = false;
        this.boxAdd = false;
      });
    },
    doDropZoneJS(file, xhr, formData) {
        formData.append('data', JSON.stringify(this.newData));
    },
    doDropZoneProcessing(){
      this.Overlay = true;
      this.fileUploadPercent = 0;
      this.isUploader = true;
    },
    doDropZoneJSError(file, message, xhr){
      if(xhr.status === 401 || xhr.status === 403){
          this.logout();
      }else{
        this.error('SERVER ERROR !!!');
      }
      this.Overlay = false;
      this.isUploader = false;
    },
    doDropZoneJSCompleted(response){
      const xhr = response.xhr
      if(xhr.status === 200){
        const json = JSON.parse(xhr.response);
        let message = (json.msg) ? json.msg:"Server Error !!!";
        if(json.success == true){
            this.selectedFile = null;
            this.fileUpload = null;
            this.fileUploadPercent = 0;
            this.success(message);
            this.fetchData();
            this.boxAdd = false;
        }else{
            this.error(message);
        }  
      }else{
        if(xhr.status === 401 || xhr.status === 403){
          this.logout();
        }else{
          this.error('SERVER ERROR !!!');
        }
      }
        this.Overlay = false;
        this.isUploader = false;
    },
    doDropZoneJSSyncProcess(totaluploadprogress){
      this.fileUploadPercent = totaluploadprogress;
    },
    async doUploadFile(){
        const formData = new FormData();
        formData.append('file', this.fileUpload);
        const Request = {
            method: 'POST',
            headers: {
                'Authorization': 'Bearer 1+1',
            },
            body: formData,
        };
        try{
          const result = await fetch('/api/upload/do', Request);
          return await result.text();
        }catch(e){
          return false;
        }
    },
    async doAdd(){
      this.Overlay = true;
      try{
        this.newData.uploaded = await doUploadFile();
      }catch(e){
        this.error(`UPLOAD ERROR: ${e}`);
        return;
      }
      fetchData(`${this.$API}/api/Update/new_add`,this.newData).then((json)=>{
          let message = (json.msg) ? json.msg:"Server Error !!!";
            if(json.success == true){
                this.selectedFile = null;
                this.fileUpload = null;
                this.fileUploadPercent = 0;
                this.success(message);
                this.fetchData();
            }else{
                this.error(message);
            }  
      },(err)=>{
        if(err === 401){
          this.logout();
        }else{
          this.error('SERVER ERROR !!!');
        }
      }).finally(()=>{
        this.Overlay = false;
        this.boxAdd = false;
      });
    },
    doDelete(bl_id){
      this.Overlay = true;
      this.$confirm(`Do you really want to delete ? This data can't restore !`,{ title: 'Warning' }).then(res => {
        if(res === true){
          fetchData(`${this.$API}/api/Update/delete`,{ bl_id },'DELETE').then((json)=>{
              let message = (json.msg) ? json.msg:"Server Error !!!";
                if(json.success == true){
                    this.success(message);
                    this.fetchData();
                }else{
                    this.error(message);
                }  
          },(err)=>{
            if(err === 401){
              this.logout();
            }else{
              this.error('SERVER ERROR !!!');
            }
          }).finally(()=>{
            this.Overlay = false;
            this.dialog = false;
          });
        }
      });
    },
  },
  mounted(){
    this.fetchData();
    this.fetchSellers();
    // this.fetchFiles();
  }
}
</script>

<style>

</style>